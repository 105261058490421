<template>
<b-card :title="$t('storage')">
  <b-tabs content-class="mt-3">
    <products />
  </b-tabs>
</b-card>
</template>
<script>
import Products from './filter/products'
export default {
    middleware: 'role:dropshipper|manager',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('storage') }
    },
    components: {
      Products
    }
}
</script>
